var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDetailsStyled', [_c('KeyValueV2Atom', {
    attrs: {
      "label": _vm.$tc('manufacturer'),
      "value": _vm.assetMapped.manufacture
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": _vm.$tc('integratedSince'),
      "value": _vm.assetMapped.integratedSince,
      "isLoading": _vm.isIntegratedDateLoading
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": _vm.$tc('maintenance.hourMeter'),
      "value": _vm.assetMapped.hourMeter
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": _vm.$tc('assetType', 1),
      "value": _vm.assetMapped.type
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": _vm.$tc('lastUpdate'),
      "value": _vm.assetMapped.lastAnalysis
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": _vm.$tc('currentLocation'),
      "value": _vm.assetMapped.currentLocation
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": _vm.$tc('technicalID'),
      "value": _vm.assetMapped.customerId
    }
  }), _vm.showEngineMeta ? [_c('KeyValueV2Atom', {
    attrs: {
      "label": _vm.$tc('engineDetails.model'),
      "value": _vm.assetMapped.engineModel
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": _vm.$tc('engineDetails.serialNumber'),
      "value": _vm.assetMapped.engineSN
    }
  })] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <AssetDetailsStyled>
    <KeyValueV2Atom :label="$tc('manufacturer')" :value="assetMapped.manufacture" />
    <KeyValueV2Atom :label="$tc('integratedSince')" :value="assetMapped.integratedSince" :isLoading="isIntegratedDateLoading" />
    <KeyValueV2Atom :label="$tc('maintenance.hourMeter')" :value="assetMapped.hourMeter" />
    <KeyValueV2Atom :label="$tc('assetType', 1)" :value="assetMapped.type" />
    <KeyValueV2Atom :label="$tc('lastUpdate')" :value="assetMapped.lastAnalysis" />
    <KeyValueV2Atom :label="$tc('currentLocation')" :value="assetMapped.currentLocation" />
    <KeyValueV2Atom :label="$tc('technicalID')" :value="assetMapped.customerId" />
    <template v-if="showEngineMeta">
      <KeyValueV2Atom :label="$tc('engineDetails.model')" :value="assetMapped.engineModel" />
      <KeyValueV2Atom :label="$tc('engineDetails.serialNumber')" :value="assetMapped.engineSN" />
    </template>
  </AssetDetailsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import units from '@/utils/units'
import get from 'lodash/get'
import { DateTime } from 'luxon'
import KeyValueV2Atom from '@/components/Atomic/Atoms/KeyValueV2Atom.vue'

const AssetDetailsStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0.25rem;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: 1rem;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`

export default {
  inject: ['uiSettings'],
  components: {
    AssetDetailsStyled,
    KeyValueV2Atom,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    isIntegratedDateLoading: {
      type: Boolean,
      default: false,
    },
    showEngineMeta: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    assetMapped() {
      return {
        manufacture: this.asset?.oem?.alias ?? '-',
        integratedSince: this.asset?.integratedSince
          ? DateTime.fromISO(this.asset?.integratedSince).setLocale(this.locale).toLocaleString(DateTime.DATE_SHORT)
          : '-',
        hourMeter: this.asset?.lastPosition
          ? units(
              this.asset.lastPosition.totalMachineHours,
              '',
              '',
              2,
              false,
              true,
              false,
              this.thousandsSeperator,
              this.decimalSeperator,
              true,
            )
          : '-',
        type: this.$t(`assetTypes.${this.asset?.type?.name?.toLowerCase()?.split(' ').join('')}`) ?? '-',
        lastAnalysis: this.asset?.lastLog
          ? DateTime.fromISO(this.asset?.lastLog)
              .setLocale(this.locale)
              .toLocaleString({
                ...DateTime.DATE_SHORT,
                ...DateTime.TIME_SIMPLE,
              })
          : '-',
        currentLocation: this.asset?.lastPosition?.geofence ?? '-',
        customerId: this.asset?.customerId ?? '-',
        engineModel: this.asset.engines.length > 0 ? this.asset.engines[0].model.name : '-',
        engineSN: this.asset.engines.length > 0 ? this.asset.engines[0].serialNumber : '-',
      }
    },
    locale() {
      return get(this.uiSettings, 'dates', 'DE_DE').toLowerCase().replace('_', '-')
    },
  },
}
</script>

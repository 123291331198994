import get from 'lodash/get'

const permissionsMixin = {
  inject: ['permissions'],
  computed: {
    dashboardWrite() {
      return get(this.permissions, 'write', []).find(p => p.name === 'dashboard_write')
    },
    dashboardRead() {
      return get(this.permissions, 'read', []).find(p => p.name === 'dashboard_read')
    },
    signalRead() {
      return get(this.permissions, 'read', []).find(p => p.name === 'signal_read')
    },
    shiftRead() {
      return get(this.permissions, 'read', []).find(p => p.name === 'shifts_read')
    },
    mapRead() {
      return get(this.permissions, 'read', []).find(p => p.name === 'map_read')
    },
    reportingCSVRead() {
      return get(this.permissions, 'read', []).find(p => p.name === 'reporting_csv_read')
    },
    reportingPDFRead() {
      return get(this.permissions, 'read', []).find(p => p.name === 'reporting_pdf_read')
    },
    machineStatusRead() {
      return get(this.permissions, 'read', []).find(p => p.name === 'machine_status_read')
    },
    geofenceReadPermission() {
      return get(this.permissions, 'read', []).find(p => p.name === 'geofence_read')
    },
    shiftsReadPermission() {
      return get(this.permissions, 'read', []).find(p => p.name === 'shifts_read')
    },
    releaseNotesReadPermission() {
      return get(this.permissions, 'read', []).find(p => p.name === 'release_notes_read')
    },
    tyreReadPermission() {
      return get(this.permissions, 'read', []).find(p => p.name === 'tyre_read')
    },
    hasNotificationsPermission() {
      return get(this.permissions, 'all', []).find(p => p.name === 'has_notifications')
    },
    hasMaintenanceAppV2Permission() {
      return get(this.permissions, 'all', []).find(p => p.name === 'has_maintenance_app_v2')
    },
    hasErrorInspectorPermission() {
      return get(this.permissions, 'all', []).find(p => p.name === 'has_error_inspector')
    },
    hasServiceDealerAppPermission() {
      return get(this.permissions, 'all', []).find(p => p.name === 'has_service_dealer_app')
    },
    hasPlanningAppPermission() {
      return get(this.permissions, 'all', []).find(p => p.name === 'has_planning_app')
    },
    hasExtensionAppPermission() {
      return get(this.permissions, 'all', []).find(p => p.name === 'has_extension_app')
    },
    hasUptimeServicePermission() {
      return get(this.permissions, 'all', []).find(p => p.name === 'has_uptime_service')
    },
    hasNextServicePermission() {
      return get(this.permissions, 'all', []).find(p => p.name === 'has_machine_next_service')
    },
  },
}

export default permissionsMixin

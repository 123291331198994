<template>
  <AssetHealthHeaderWrapperStyled>
    <AssetHealthHeaderStyled v-if="asset">
      <div class="row1">
        <AssetNameMolecule class="name" :typeName="asset.type.name" :assetName="asset.name" />
        <LoadingDots class="loader" v-if="isLoading" />
        <SeverityMolecule v-else class="severity" :severities="asset.assetSeverities" />
        <InsightsCountMolecule v-if="assetIssues" class="insights" :value="assetIssues.length" />
        <SwitchChildRouteAtom v-if="!isSmall" class="routes" :childRoutes="childRoutes" />
      </div>
      <div class="row2">
        <AssetDetailsMolecule
          class="details"
          :asset="assetDataWithIntegratedDate"
          :isIntegratedDateLoading="isIntegrationDateLoading"
          :showEngineMeta="showEngineMeta"
        />
      </div>
      <div class="row3" v-if="isSmall">
        <SwitchChildRouteAtom class="routes" :childRoutes="childRoutes" />
      </div>
    </AssetHealthHeaderStyled>
  </AssetHealthHeaderWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import SwitchChildRouteAtom from '@/components/Atomic/Atoms/SwitchChildRouteAtom.vue'
import AssetNameMolecule from '@/components/Atomic/Molecules/AssetPageHeader/AssetNameMolecule'
import AssetDetailsMolecule from '@/components/Atomic/Molecules/AssetPageHeader/AssetDetailsMolecule'
import SeverityMolecule from '@/components/Atomic/Molecules/SeverityMolecule'
import InsightsCountMolecule from '@/components/Atomic/Molecules/Maintenance/InsightsCountMolecule'
import { getLast24HoursInterval } from '../../../utils/time'
import { LoadingDots } from '@common/components'
import permissionsMixin from '@/mixins/permissions'

import ASSET_ISSUES_QUERY from '#/graphql/operations/maintenance/assetIssuesQuery.gql'
import ASSET_INTEGRATION_DATE_QUERY from '#/graphql/operations/asset/assetIntegrationDate.gql'

const AssetHealthHeaderWrapperStyled = styled('div')`
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 1rem 0 0rem 0;
`

const AssetHealthHeaderStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.4fr 0.6fr;
  border: 1px solid ${({ theme }) => theme.colors.mainBG};
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 8px;

  border: solid 0.5px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  .row1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'name severity insights routes';
    border-bottom: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
    align-items: center;
    padding: 0.5rem 1rem;
  }
  .row2 {
    display: grid;
    grid-template-columns: 58px 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'icon details details details details';
    padding: 0.5rem 1rem;
  }
  .name {
    grid-area: name;
  }
  .severity {
    grid-area: severity;
  }
  .insights {
    grid-area: insights;
  }
  .routes {
    grid-area: routes;
    justify-content: end;
  }
  .details {
    grid-area: details;
  }
  @media (max-width: 1024px) {
    .row1 {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'name severity'
        'name insights';
      grid-gap: 0.5rem;
      .severity {
        justify-self: end;
      }
      .insights {
        justify-self: end;
      }
    }
    .row2 {
      grid-template-columns: 1fr 1fr min-content;
      grid-template-areas:
        'details details details'
        'details details details';
      border-bottom: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
    }
    .row3 {
      padding: 0.5rem;
    }
  }
  @media (max-width: 820px) {
    .row1 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        'name name'
        'severity insights';
      grid-gap: 0.5rem;
      .severity {
        justify-self: end;
      }
      .insights {
        justify-self: end;
      }
    }
    .row2 {
      grid-template-columns: 1fr;
      grid-template-areas:
        'details'
        'details'
        'details'
        'details';
      .details {
        column-gap: 1rem;
      }
    }
    .row3 {
      padding: 0.1rem 0.5rem;
    }
  }
  @media (max-width: 425px) {
    .row1 {
      grid-template-columns: 1fr;
      grid-template-areas:
        'name'
        'severity'
        'insights';
      .name {
        justify-self: start;
      }
      .severity {
        justify-self: start;
      }
      .insights {
        justify-self: start;
      }
    }
    .row3 {
      .routes {
        justify-content: center;
      }
    }
  }
`
export default {
  inject: ['uiSettings'],
  mixins: [permissionsMixin],
  components: {
    AssetHealthHeaderWrapperStyled,
    AssetHealthHeaderStyled,
    AssetNameMolecule,
    SeverityMolecule,
    SwitchChildRouteAtom,
    AssetDetailsMolecule,
    InsightsCountMolecule,
    LoadingDots,
  },
  props: {
    asset: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      availableSubComponents: [
        { id: 'All', label: 'All' },
        { id: 'Engine', label: 'Engine' },
        { id: 'Tyre', label: 'Tyre' },
        { id: 'bigComponent', label: 'Big Component' },
      ],
      selectedSubComponent: { id: 'All', label: 'All' },
      assetIssues: null,
      isIntegrationDateLoading: false,
      assetIntegrationDate: null,
    }
  },
  created() {
    this.screenWidth = window.innerWidth
    window.addEventListener('resize', this.onResize)
    if (!this.asset?.currentLoggerRelation) {
      this.fetchAssetIntegrationDate()
    }
  },
  computed: {
    childRoutes() {
      const maintenanceRoutes = [
        {
          name: 'AssetHealthOverview',
          label: this.$t('navigation.subRoutes.AssetHealthOverview'),
        },
        {
          name: 'AssetHealthInsight',
          label: this.$t('navigation.subRoutes.AssetHealthInsight'),
        },
        {
          name: 'AssetHealthStatistics',
          label: this.$t('navigation.subRoutes.AssetHealthStatistics'),
        },
      ]
      const sdRoutes = [
        {
          name: 'ServiceDealerAssetHealthOverview',
          label: this.$t('navigation.subRoutes.ServiceDealerAssetHealthOverview'),
        },
        {
          name: 'ServiceDealerAssetHealthInsight',
          label: this.$t('navigation.subRoutes.ServiceDealerAssetHealthInsight'),
        },
        {
          name: 'ServiceDealerAssetHealthStatistics',
          label: this.$t('navigation.subRoutes.ServiceDealerAssetHealthStatistics'),
        },
      ]
      return this.$route.meta?.isSDApp ? sdRoutes : maintenanceRoutes
    },
    isSmall() {
      return this.screenWidth < 1025
    },
    last24HoursInterval() {
      return getLast24HoursInterval()
    },
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2).toLowerCase() + replacedDates.slice(2, 5)
    },
    assetDataWithIntegratedDate() {
      return {
        ...this.asset,
        integratedSince: this.asset?.currentLoggerRelation?.fromTime ?? this.assetIntegrationDate,
      }
    },
    showEngineMeta() {
      return !this.$route.path.includes('overview') && this.hasUptimePermission
    },
    hasUptimePermission() {
      return Boolean(this.hasUptimeServicePermission)
    },
  },
  methods: {
    onResize() {
      this.screenWidth = window.innerWidth
    },
    goToLocation() {},
    changeSelectedSubComponent(n) {
      this.selectedSubComponent = n
    },
    async fetchAssetIntegrationDate() {
      if (!this.asset?.id) return
      try {
        this.isIntegrationDateLoading = true
        const { data } = await this.$apollo.query({
          query: ASSET_INTEGRATION_DATE_QUERY,
          variables: {
            assetId: this.asset?.id,
          },
        })
        this.assetIntegrationDate = data.assetIntegrationDate?.integratedSince ?? null
      } catch (err) {
        this.isIntegrationDateLoading = false
        this.assetIntegrationDate = null
      } finally {
        this.isIntegrationDateLoading = false
      }
    },
  },
  apollo: {
    assetIssues: {
      query: ASSET_ISSUES_QUERY,
      variables() {
        return {
          where: {
            interval: this.last24HoursInterval.toISO(),
            assetIds: [this.asset.id],
            locale: this.locale,
          },
        }
      },
      skip() {
        return !this.asset?.id
      },
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>
